<template>
  <div class="data-table-container">
    <Card>
      <template #title> {{ cardTitle }} {{ displayWeek }} </template>
      <template #content>
        <DataTable
          class="p-datatable-sm "
          :value="appointments"
          :loading="isLoading"
          :rowHover="true"
          editMode="cell"
          sortMode="multiple"
          :multiSortMeta="multiSortMeta"
          :rowClass="rowClass"
          filterDisplay="row"
          :stateKey="tableState.filterName"
          stateStorage="local"
          :rows.sync="tableState.pagination.rowsPerPage"
          dataKey="id"
          :paginator="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 15, 20]"
          :filters.sync="tableState.filters"
          @cell-edit-complete="onCellEditComplete"
          @sort="onSort($event)"
          @filter="onFilter($event)"
          @state-restore="onStateRestore($event)"
        >
          <template #empty>
            {{ $t('no_data_found') }}
          </template>
          <template #loading>
            {{ $t('loading') }}
          </template>

          <template #groupheader="slotProps">
            <strong>{{ slotProps.data.weekday }}</strong>
          </template>

          <Column
            field="appointmentType"
            :header="$t('appointmentType')"
            :styles="{ width: '7%' }"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.data.appointmentType)">
                {{ $t(slotProps.data.appointmentType) }}
              </b-badge>
            </template>

            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="getEnumValues('AppointmentType')"
                :placeholder="$t('select')"
              >
                <template #option="slotProps">
                  <b-badge :variant="getAppointmentTypeColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
                <template #value="slotProps">
                  <b-badge :variant="getAppointmentTypeColor(slotProps.value)">
                    {{ $t(slotProps.value) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>

            <template #filter>
              <MultiSelect
                :value="tableState.customFilters.appointmentTypes"
                :options="appointmentTypes"
                @input="onAppointmentTypeFilter"
                optionLabel="label"
                :placeholder="$t('appointmentType')"
                display="chip"
                :style="{ 'max-width': '100px' }"
              />
            </template>
          </Column>

          <Column
            field="appointmentState"
            :header="$t('appointmentState')"
            :showFilterMenu="false"
            :styles="{ width: '7%' }"
          >
            <template #body="slotProps">
              <b-badge :variant="getAppointmentStateColor(slotProps.data.appointmentState)">
                {{ $t(slotProps.data.appointmentState) }}
              </b-badge>
            </template>

            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="getEnumValues('AppointmentState')"
                :placeholder="$t('select')"
              >
                <template #option="slotProps">
                  <b-badge :variant="getAppointmentStateColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
                <template #value="slotProps">
                  <b-badge :variant="getAppointmentStateColor(slotProps.value)">
                    {{ $t(slotProps.value) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>

            <template #filter>
              <MultiSelect
                :value="tableState.customFilters.appointmentStates"
                :options="appointmentStates"
                @input="onAppointmentStateFilter"
                optionLabel="label"
                :placeholder="$t('appointmentState')"
                display="chip"
                :style="{ 'max-width': '100px' }"
              />
            </template>
          </Column>

          <Column
            field="appointmentApprovedState"
            :header="$t('appointmentApprovedState_short')"
            :styles="{ width: '7%' }"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.data.appointmentApprovedState)">
                {{ $t(slotProps.data.appointmentApprovedState) }}
              </b-badge>
            </template>

            <template #editor="{ data, field }">
              <Dropdown v-model="data[field]" :options="yesNo()" :placeholder="$t('select')">
                <template #option="slotProps">
                  <b-badge :variant="getYesNoStateColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
                <template #value="slotProps">
                  <b-badge :variant="getYesNoStateColor(slotProps.value)">
                    {{ $t(slotProps.value) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>

            <template #filter>
              <Dropdown
                :value="tableState.filters.appointmentApprovedState.value"
                :options="yesNo()"
                class="p-column-filter"
                @input="onAppointmentApprovedStateFilter"
                :style="{ 'max-width': '100px' }"
              >
                <template #option="slotProps">
                  <b-badge :variant="getYesNoStateColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column
            field="installationStartAt"
            :header="$t('start')"
            :styles="{ width: '8%' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              {{ $datetime(slotProps.data[slotProps.column.field]) }}
            </template>
            <template #editor="slotProps">
              <Calendar
                v-model="slotProps.data[slotProps.column.field]"
                :showTime="true"
                :manualInput="true"
                :stepMinute="15"
              >
              </Calendar>
            </template>
          </Column>
          <Column
            field="installationEndAt"
            :header="$t('end')"
            :styles="{ width: '8%' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              {{ $datetime(slotProps.data[slotProps.column.field]) }}
            </template>
            <template #editor="slotProps">
              <Calendar
                v-model="slotProps.data[slotProps.column.field]"
                :showTime="true"
                :manualInput="true"
                :stepMinute="15"
              >
              </Calendar>
            </template>
          </Column>

          <Column
            field="project.number"
            header="#"
            :styles="{ width: '7%' }"
            :headerStyle="{ width: '60px' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              <router-link
                v-if="slotProps.data.project && slotProps.data.project.number"
                target="_blank"
                :to="{
                  name: 'ProjectEditPage',
                  params: { projectNumber: slotProps.data.project.number },
                }"
                >{{ slotProps.data.project.number.toString().padStart(4, '0') }}
                <i class="fa fa-sm fa-external-link"></i>
              </router-link>
            </template>
          </Column>

          <template #footer>
            {{ getAppointments ? getAppointments.length : 0 }} Termine
            <span v-if="calendarWeek[0]">
              im Zeitraum
              {{ calendarWeek[0] ? $date(calendarWeek[0]) : 'N/A' }} bis
              {{ calendarWeek[0] ? $date(calendarWeek[1]) : 'N/A' }}
            </span>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';
import Card from 'primevue/card';

export default {
  name: 'AppointmentTable',
  components: {
    Card,
    DataTable,
    Calendar,
    Column,
    Dropdown,
    MultiSelect,
  },

  props: {
    selectedWeek: {
      type: String, // 'last', 'current', 'next'
      required: true,
    },
    selectedTypes: {
      type: Array,
      required: true,
    },
    cardTitle: {
      type: String,
      required: false,
      default: 'Widget Name',
    },
  },

  data() {
    return {
      monthChangeClicked: false,
      copyDate: this.$dayjs()
        .startOf('day')
        .add(8, 'hour')
        .toDate(),
      calendarWeek: [],
      filteredEmployees: [],
      expandedRowGroups: null,
      loading: false,
      multiSortMeta: [{ field: 'installationStartAt', order: 1 }],

      /** filters for project multiselect */
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'appointments-project-filters',
        filters: {
          number: { value: null, matchMode: FilterMatchMode.EQUALS },
          customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
          customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },

      /** persistent table filters  */
      defaultFilters: {
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      defaultCustomFilters: {
        from: {
          value: this.$dayjs()
            .startOf('week')
            .toDate(),
          matchMode: FilterMatchMode.EQUALS,
        },
        to: {
          value: this.$dayjs()
            .startOf('week')
            .add(6, 'days')
            .toDate(),
          matchMode: FilterMatchMode.EQUALS,
        },
        appointmentStates: [],
        appointmentTypes: [],
      },

      tableState: {
        filterName: `appointmentTable-${this.cardTitle}-table-filters`,
        customFilterName: `appointmentTable-${this.cardTitle}-custom-table-filters`,
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
        pagination: {
          rowsPerPage: 10,
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getAppointments', 'getEnumValues', 'getProjects', 'getEmployees']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('PROJECTS_DASHBOARD') }];
    },
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
    displayWeek() {
      const today = this.$dayjs();
      const currentWeek = today.isoWeek();

      switch (this.selectedWeek) {
        case 'last':
          return currentWeek - 1;
        case 'next':
          return currentWeek + 1;
        case 'current':
        default:
          return currentWeek;
      }
    },
    filteredAppointments() {
      return this.appointments.filter((appointment) => {
        return this.selectedTypes.includes(appointment.type);
      });
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchAppointments',
      'createAppointment',
      'duplicateAppointment',
      'updateAppointment',
      'initAppointment',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployees',
      'deleteAppointment',
      'sendAppointmentMail',
    ]),
    applyCustomDateRange() {
      if (this.defaultCustomFilters.from.value && this.defaultCustomFilters.to.value) {
        this.onCustomDateRangeFilter(
          this.defaultCustomFilters.from.value,
          this.defaultCustomFilters.to.value
        );
      }
    },

    onFromDateSelect(date) {
      this.defaultCustomFilters.from.value = date;
      if (this.defaultCustomFilters.to.value) {
        this.onCustomDateRangeFilter(date, this.defaultCustomFilters.to.value);
      }
    },
    onToDateSelect(date) {
      this.defaultCustomFilters.to.value = date;
      if (this.defaultCustomFilters.from.value) {
        this.onCustomDateRangeFilter(this.defaultCustomFilters.from.value, date);
      }
    },

    onCalendarMonthChange(e, data) {
      this.monthChangeClicked = true;
    },

    resetPreventHide() {
      this.$nextTick(() => {
        this.monthChangeClicked = false;
      });
    },

    /**
     *
     */
    async onProjectSearch(rawQuery) {
      const query = rawQuery.toLowerCase();
      this.projectsFilter.filters.customerLastname.value = query;
      await this.loadProjectData();
    },

    async onSelectProject(row) {
      const { data } = row;
      this.save(data);
    },

    async onSelectEmployee(row) {
      console.log(row);
      const { data } = row;
      this.save(data);
    },

    /**
     * send appointment data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      // console.log('onCellEditComplete():', event);
      // console.log(data, newValue, field);

      if (field === 'installationStartAt') {
        // update date grouping (day of week)
        data.weekday = this.$dayjs(newValue)
          .startOf('day')
          .format('dddd DD.MM.YYYY');

        // set installationEndAt to installationStartAt + 4 hour
        data.installationEndAt = this.$dayjs(newValue)
          .add(8, 'hour')
          .toDate();
      }

      data[field] = newValue;

      this.save(data);
    },

    async save(appointment) {
      if (appointment.number === '_new') {
        await this.createAppointment(appointment);
      } else {
        await this.updateAppointment(appointment);
      }
    },

    onCreateAppointment() {
      this.initAppointment();
      // this.expandAll();
    },

    /**
     * Copy the appointment data to a new appointment
     * Set the start date selected in the ConfirmButton Popup
     * @param {*} appointment
     */
    async onDuplicateAppointment(appointment) {
      const newAppointment = await this.duplicateAppointment({
        appointment: appointment,
        installationStartAt: this.copyDate,
      });
      // this.expandAll();
      this.save(newAppointment);
      appointment.appointmentState = 'ALTERNATIVE_DATE';
      this.save(appointment);
    },

    onDeleteAppointment(appointmentNumber) {
      // console.log('onDeleteAppointment():', appointmentNumber);
      this.deleteAppointment(appointmentNumber);
    },

    /**
     * send confirmation mail
     */
    async onSendAppointmentConfirmationMail(appointment) {
      await this.sendAppointmentMail({
        appointmentNumber: appointment.number,
      });
    },

    expandAll() {
      this.expandedRowGroups = this.getAppointments.map((a) => a.weekday);
    },
    collapseAll() {
      this.expandedRowGroups = null;
    },

    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    /**
     * Load remote table data
     */
    async loadAppointmentData() {
      await this.fetchAppointments({
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });
    },

    async loadProjectData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      }
      this.filteredProjects = this.getProjects;
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      // console.log('onFilter():', event);
      // this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentApprovedStateFilter(value) {
      if (this.tableState.filters.appointmentApprovedState) {
        this.tableState.filters.appointmentApprovedState.value = value;
      }

      await this.loadAppointmentData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      // console.log('onStateRestore():', this.tableState);

      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem(this.tableState.customFilterName)
      );
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;

      this.setupFilters();
    },

    /**
     * When selecting a single day, add 13 days to the date filter to represent two whole weeks.
     * Then close the calendar overlay.
     * @param {*} value
     */
    async onCalendarWeekFilter(value) {
      this.calendarWeek[1] = this.$dayjs(value)
        .add(6, 'days')
        .toDate();
      this.tableState.customFilters.from.value = this.calendarWeek[0];
      this.tableState.customFilters.to.value = this.calendarWeek[1];
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      this.$refs.appointmentCalendar.overlayVisible = false;
      await this.loadAppointmentData();
    },

    /**
     * Filtert appointments mit einer range und laedt die daten
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @async
     * @returns {Promise<void>}
     */

    async onCustomDateRangeFilter(startDate, endDate) {
      this.defaultCustomFilters.from.value = this.$dayjs(startDate).toDate();

      // Add one day to the end date for filtering
      const endDateInclusive = this.$dayjs(endDate)
        .add(1, 'day')
        .toDate();

      this.tableState.customFilters.from.value = this.defaultCustomFilters.from.value;
      this.tableState.customFilters.to.value = endDateInclusive;

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      this.$refs.appointmentCalendar.overlayVisible = false;
      await this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentStateFilter(value) {
      this.tableState.customFilters.appointmentStates = this.appointmentStates.filter(
        (appointmentState) => value.includes(appointmentState)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentTypeFilter(value) {
      this.tableState.customFilters.appointmentTypes = this.appointmentTypes.filter(
        (appointmentType) => value.includes(appointmentType)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    setupFilters() {
      // Beibehaltung der bereits vorhandenen Initialisierungslogik
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;

      // Logik zum Einstellen des Zeitraums
      const today = this.$dayjs();
      switch (this.selectedWeek) {
        case 'last':
          this.tableState.customFilters.from.value = today
            .subtract(1, 'week')
            .startOf('week')
            .toDate();
          this.tableState.customFilters.to.value = today
            .subtract(1, 'week')
            .endOf('week')
            .toDate();
          break;
        case 'next':
          this.tableState.customFilters.from.value = today
            .add(1, 'week')
            .startOf('week')
            .toDate();
          this.tableState.customFilters.to.value = today
            .add(1, 'week')
            .endOf('week')
            .toDate();
          break;
        case 'current':
        default:
          this.tableState.customFilters.from.value = today.startOf('week').toDate();
          this.tableState.customFilters.to.value = today.endOf('week').toDate();
          break;
      }

      // Aktualisierung des calendarWeek-Arrays
      this.calendarWeek = [
        this.tableState.customFilters.from.value,
        this.tableState.customFilters.to.value,
      ];

      // Aktualisierung der Terminarten
      this.tableState.customFilters.appointmentTypes = this.selectedTypes;

      // Aktualisierung des Local Storage
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
    },
    getAppointmentTypeColor,
    getAppointmentStateColor,
    getYesNoStateColor,
    yesNo,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('AppointmentState');
    await this.fetchEmployees({
      page: 0,
      pageSize: 100,
      sortField: 'lastname',
      sortOrder: 1,
      filters: {},
    });
    this.filteredEmployees = this.getEmployees;

    // Anpassung für selectedTypes
    this.tableState.customFilters.appointmentTypes = this.selectedTypes;

    // Speichern der angepassten Filter im Local Storage
    localStorage.setItem(
      this.tableState.customFilterName,
      JSON.stringify(this.tableState.customFilters)
    );

    // Laden der Daten
    await this.loadAppointmentData();
    await this.loadProjectData();
    // await this.onRemoveFilters();
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}
.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 0.5em;
}

.grid > div {
  padding: 1em;
  border: 2px;
  //   background: linear-gradient(180deg, #30323d 0%, #2e3542 100%);
}
.logo {
  width: 88px;
}
.p-card {
  //   background: rgba(255, 255, 255, 0.05);
  //   color: white;
  color: $text-color;
}
.data-table-container {
  overflow-x: auto;
}
</style>
