<template>
  <div class="dashboard">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <div class="grid">
      <NewTasksCard />

      <Card>
        <template #title>
          Zu Terminieren
        </template>
        <template #content>
          <div class="flex-row">
            <div class="flex-column">
              <h2>{{ getMeta.count_project_state_new_without_measurement_appointment }}</h2>
              <h6>Neukunden ohne AM Termin</h6>
            </div>
            <div class="flex-column">
              <h2>
                {{
                  getMeta.count_project_state_planning_due_diligence_without_ac_or_dc_appointment
                }}
              </h2>
              <h6>Zu terminierenden Kunden</h6>
            </div>
            <div class="flex-column">
              <h2>{{ getMeta.count_project_with_dc_appointment_and_without_ac_appointment }}</h2>
              <h6>zu terminierenden AC Termine</h6>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-column">
              <h2>{{ getMeta.count_projects_with_dc_or_ac_appointments_in_state_backlog }}</h2>
              <h6>Backlog DC/AC</h6>
            </div>
            <div class="flex-column">
              <h2>{{ getMeta.count_projects_with_dc_appointments_in_state_backlog }}</h2>
              <h6>Backlog DC</h6>
            </div>
            <div class="flex-column">
              <h2>{{ getMeta.count_projects_with_ac_appointments_in_state_backlog }}</h2>
              <h6>Backlog AC</h6>
            </div>
          </div>
        </template>
      </Card>

      <!-- <AppointmentTable
        :cardTitle="'Montage in aktueller KW: '"
        :selectedWeek="'current'"
        :selectedTypes="[{ value: 'AC' }, { value: 'DC' }, { value: 'AC / DC' }]"
      /> -->
      <!-- <AppointmentTable
        :cardTitle="'Montage in nächste KW: '"
        :selectedWeek="'next'"
        :selectedTypes="[{ value: 'AC' }, { value: 'DC' }, { value: 'AC / DC' }]"
      />
      <AppointmentTable
        :cardTitle="'Montage in aktueller KW: '"
        :selectedWeek="'current'"
        :selectedTypes="[{ value: 'AC' }, { value: 'DC' }, { value: 'AC / DC' }]"
      />
      <AppointmentTable
        :cardTitle="'Montage in aktueller KW: '"
        :selectedWeek="'current'"
        :selectedTypes="[{ value: 'AC' }, { value: 'DC' }, { value: 'AC / DC' }]"
      />
      <AppointmentTable
        :cardTitle="'Montage in aktueller KW: '"
        :selectedWeek="'current'"
        :selectedTypes="[{ value: 'AC' }, { value: 'DC' }, { value: 'AC / DC' }]"
      /> -->
      <!-- 
      <div>
        <Card>
          <template #header>
            <img class="logo" src="../../assets/logo2.png" />
          </template>
          <template #title>
            Advanced Card
          </template>
          <template #content>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur
            error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis,
            culpa ratione quam perferendis esse, cupiditate neque quas!
          </template>
        </Card>
      </div>
      <div>Plain Content</div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import NewTasksCard from '@/components/Tasks/NewTasksCard';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import AppointmentTable from '@/components/Appointments/AppointmentTable';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';
import Card from 'primevue/card';

export default {
  name: 'DashboardDev',
  components: {
    Card,
    Breadcrumbs,
    NewTasksCard,
    // AppointmentTable,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getAppointments',
      'getEnumValues',
      'getProjects',
      'getEmployees',
      'getMeta',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('PROJECTS_DASHBOARD') }];
    },
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
  },
  methods: {
    ...mapActions(['fetchMeta']),
  },
  async created() {},
  async mounted() {
    this.fetchMeta();
  },
};
</script>

<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  gap: 1em;
  margin-bottom: 1em;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  background: rgba(0, 0, 32, 0.03);
  padding: 0.5em;
  // border: 1px solid red;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}
.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 0.5em;
}

.grid > div {
  padding: 0em;
  // border: 2px;
  // background: linear-gradient(180deg, #30323d 0%, #2e3542 100%);
}
.logo {
  width: 88px;
}
.p-card {
  // background: rgba(255, 255, 255, 0.05);
  // color: white;
  color: $text-color;
}
.data-table-container {
  overflow-x: auto;
}
// ::v-deep .p-card .p-card-content {
//   padding: 0 0;
// }
</style>
